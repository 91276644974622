import React from 'react';
import {Link, graphql, useStaticQuery} from 'gatsby';

import Layout from '../../../components/Layout';
import SEO from '../../../components/seo';
import Content from '../../../components/Content';
import Block from '../../../components/Block';
import Hero from '../../../components/Hero';
import {WireFlexChild} from '../../../components/wire/WireFlex';
import WireFlex from '../../../components/wire/WireFlex';
import {getSectorLink} from '../../../helpers/links';
import {lang} from '../../../helpers/lang';
import SectorItem from '../../../components/SectorItem';
import SectorGridList from '../../../components/GridList';
import {getSectors} from '../../../selectors/data';

const sectors = getSectors();

const renderSectors = (count, lang) => {
	return (
		sectors
			// .slice(0, count)
			.map(sector => (
				<SectorItem id={sector.key} key={sector.key} lang={lang} />
			))
	);
};

const ParentSectorsPage = ({pageContext}) => {
	const data = useStaticQuery(graphql`
		{
			background: file(relativePath: {eq: "pages/sectors/horizont.jpg"}) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Sectors" />
			<Hero
				backgroundImageFluid={data.background.childImageSharp.gatsbyImageData}
			>
				<h1>Sectors</h1>
				<p></p>
			</Hero>
			<Block light accent className="gst-block-work">
				<Content>
					<SectorGridList>{renderSectors(2, lang)}</SectorGridList>
				</Content>
			</Block>
		</Layout>
	);
};

export default ParentSectorsPage;
